import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import videojs from "video.js";
// import 'videojs-contrib-ads';
// import 'videojs-ima';
// import 'videojs-contrib-quality-levels';
import hlsQualitySelector from 'videojs-hls-quality-selector';
// import 'video.js/dist/video-js.css';
// import "videojs-ima/src/css/videojs.ima.css";
import watermark from 'videojs-watermark';
import iscrrenWatermark from 'assets/iscreen/iscreen_gray.png';
import funprimeWatermark from 'assets/funprime_logo.png';
import './watermark.css';
import eyeSvg from 'assets/icons/eye-svg.svg';
import firebase from 'firebase';
import jwtDecode from 'jwt-decode';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdSkipNext } from 'react-icons/md';
import { API } from 'utils/api.config';

export default function PlayVideo(props) {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  // const watchTimeRef = React.useRef(null);
  const {
    options,
    onReady,
    userProfiles,
    onEnd,
    setVideoDuration,
    onSkipEpisode,
    seriesId,
    showSkipTrailerBtn,
    onHideSkipTrailer,
    seriesIndex,
    episodeIndex,
    from,
    setEpisodeIndex,
    setSeriesIndex
  } = props;
  videojs.registerPlugin('watermark', watermark);
  videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
  const platform = window.platform_info.api_key;
  const [liveCount, setLiveCount] = useState();
  const title = window?.platform_info.title;
  const token =
    JSON.parse(localStorage.getItem(`access_token_r_${title}`)) ||
    JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
  const userTokenData = jwtDecode(token);
  const couponUser = localStorage.getItem(`coupon_login_${title}`);

  console.log('Season Index through props', seriesIndex);
  console.log('Episode Index through props', episodeIndex);
  console.log('From slug or not', from);

  const location = useLocation();
  let isChannel;
  if (location?.pathname.startsWith('/channel')) {
    isChannel = true;
  }
  const { vastTag, currentTrack, tracks } = useSelector((state) => state.video);
  const tempAdTag =
    'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostpodbumper&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator';

  // Skip Intro & Endtitle related Variables
  const [showIntroBtn, setShowIntroBtn] = useState();
  const [showEndtitleBtn, setShowEndtitleBtn] = useState();
  var introStart = currentTrack?.introDuration;
  var introEnd = currentTrack?.endIntroDuration;
  var outroStart = currentTrack?.outroDuration;
  var intro = currentTrack?.intro;
  var outro = currentTrack?.outro;
  localStorage.setItem('introStart', introStart);
  localStorage.setItem('introEnd', introEnd);
  localStorage.setItem('outroStart', outroStart);
  localStorage.setItem('isIntro', intro);
  localStorage.setItem('isOutro', outro);

  //continuous track of player current time
  var globalCurrentTime;

  //Set IMA ad options here
  const imaOptions = {
    adTagUrl: vastTag?.is_vast_show ? vastTag?.vast_tag : '',
    adLabel: 'Advertisement'
  };

  const { currentUser } = useSelector((state) => state.user);
  console.log('CurrentUser', currentUser);
  const asthaUser = localStorage.getItem(`astha_user_${title}`);
  const gpUser = localStorage.getItem(`gp_user_${title}`);
  const robiUser = localStorage.getItem(`robi_user_${title}`);
  const history = useHistory();

  // live count update handler
  function updateLiveCount(id) {
    const userCountRef = firebase.database().ref('live_user_count');
    userCountRef.child(currentTrack?.id).child(`web_${id}`).set('w');
  }

  // Show count handler
  function showCount() {
    const userCountRef = firebase.database().ref('live_user_count');
    userCountRef.child(currentTrack?.id).on('value', (snap) => {
      if (snap.exists()) {
        setLiveCount(snap.numChildren());
      }
    });
  }

  // live count remove handler
  function removeLiveCount(id) {
    firebase
      .database()
      .ref('live_user_count')
      .child(currentTrack?.id)
      .child(`web_${id}`)
      .remove()
      .then(() => {
        console.log('Remove Succeeded');
      })
      .catch((error) => {
        console.log('Remove Failed', error.message);
      });
  }

  // Skip intro handler
  function onSkipIntro(e) {
    const player = playerRef.current;
    player.currentTime(introEnd);
    setShowIntroBtn(false);
  }

  /******
   * Hide intro and Endtitle button whenever series episode changes
   */
  useEffect(() => {
    // This code will run whenever the location (including search parameters) changes.

    // For example, you can parse the search parameters using URLSearchParams
    const searchParams = new URLSearchParams(location.search);
    const paramName = searchParams.get('episode_index');

    if (paramName || episodeIndex) {
      setShowIntroBtn(false);
      setShowEndtitleBtn(false);
    }
  }, [location.search, episodeIndex]); // The effect will run whenever location.search changes

  /**
   * Live Count Feature
   *
  useEffect(() => {
    if (!isChannel) {
      window.addEventListener('beforeunload', (e) => {
        removeLiveCount(userTokenData?.id);
      });
    }

    return () => {
      if (!isChannel) {
        window.removeEventListener('beforeunload', (e) => {
          removeLiveCount(userTokenData?.id);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!isChannel) {
      if (currentTrack?.live) {
        showCount();
      }
    }
  }, []);

  useEffect(() => {
    if (!isChannel) {
      if (currentTrack?.live) {
        if (userTokenData?.id) {
          //if we have userprofile id
          // do firebase realtime listen
          updateLiveCount(userTokenData?.id);
        }
        // else {
        // we dont have userprofile id
        // call profile api v2
        // then do firebase realtime things
        //   getProfileData().then(res => {
        //     updateLiveCount(res)
        //   })
        // }
      }
    }

    return () => {
      if (!isChannel && currentTrack?.live) {
        removeLiveCount(userTokenData?.id);
      }
    }
  }, [userTokenData?.id, currentTrack?.live])
  *
  * Live Count Feature End
  */

  // Videojs metadata handler
  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (!video) return;
    if (!currentTrack?.live) {
      setVideoDuration && setVideoDuration(video?.duration);
    }
  };

  /**
   * Videojs Initialization
   */
  useEffect(() => {
    // make sure Video.js player is only initialized once
    const videoElement = videoRef.current;
    videoElement.onended = function () {
      onEnd();
      /*
      if (!isChannel) {
        if (currentTrack?.live) {
          removeLiveCount(userTokenData?.id);
        }
      }
      */
    };
    if (!playerRef.current) {
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
      // Add the videojs-contrib-quality-levels plugin to the player
      // player.qualityLevels();
      // player.qualityLevels();

      // This is optional, but you can listen for the 'qualitychange' event to track quality changes
      // player.qualityLevels().on('change', () => {
      //   const currentQuality = player.qualityLevels().currentQuality();
      //   console.log('Current quality:', currentQuality.label);
      // });
      !userProfiles?.subscribe && !gpUser && !robiUser && !asthaUser && !currentTrack?.live
        ? player.ima(imaOptions)
        : '';
      player.hlsQualitySelector({ displayCurrentQuality: true });
      currentTrack?.watermark
        ? player.watermark({
            // image: "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-2000w,f_auto,q_auto:best/streams/2013/January/130129/1C5766347-google-logo.jpg",
            image:
              platform === 'iscreen'
                ? currentTrack?.type === 'video' || currentTrack?.type === 'episode'
                  ? iscrrenWatermark
                  : ''
                : platform === 'funprime'
                ? ''
                : '',
            fadeTime: null,
            url:
              platform === 'iscreen'
                ? 'https://iscreen.com.bd'
                : platform === 'funprime'
                ? null
                : null
          })
        : '';
      player.on('timeupdate', function () {
        let currentTime = player.currentTime();
        localStorage.setItem('currentTime', currentTime);
        console.log('Current time:', currentTime);
        const localIntroStart = localStorage.getItem('introStart');
        const localIntroEnd = localStorage.getItem('introEnd');
        const localOutroStart = localStorage.getItem('outroStart');
        const localIsIntro = localStorage.getItem('isIntro');
        const localIsOutro = localStorage.getItem('isOutro');

        if (localIsIntro) {
          if (parseInt(currentTime) > localIntroStart && parseInt(currentTime) < localIntroEnd) {
            setShowIntroBtn(true);
          } else {
            setShowIntroBtn(false);
          }
        }
        if (localIsOutro) {
          if (parseInt(currentTime) > localOutroStart) {
            setShowEndtitleBtn(true);
          } else {
            setShowEndtitleBtn(false);
          }
        }
      });
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  /**
   * Hide endtitle button when component unmounts
   */
  React.useEffect(() => {
    return () => {
      setShowEndtitleBtn(false);
    };
  }, []);

  // Post watchdata function for posting watchdata
  function postWatchData() {
    let postData;
    // Getting Season Id & Episode Id from QueryParam
    const searchParams = new URLSearchParams(location.search);
    const episodeId = searchParams.get('episode_index');
    const seasonId = searchParams.get('season_index');
    const currentTime = parseInt(localStorage.getItem('currentTime'));
    let sIndex = parseInt(seriesIndex) + 1;
    let eIndex = parseInt(episodeIndex) + 1;
    console.log('Season & Episode Id', seasonId, episodeId);
    console.log('Season & Episode id for Slug', sIndex, eIndex);

    const videoData = {
      content_id: currentTrack?.id,
      type: currentTrack?.type,
      time: currentTime
    };
    const seriesData = {
      content_id: seriesId,
      season_id: parseInt(seasonId),
      episode_id: parseInt(episodeId),
      type: 'series',
      time: currentTime
    };
    let seriesDataSlug = {
      content_id: seriesId,
      season_id: sIndex,
      episode_id: eIndex,
      type: 'series',
      time: currentTime
    };
    if (currentTrack?.type === 'video') {
      postData = videoData;
    } else if (currentTrack?.type === 'episode') {
      if (from != 'slug') {
        postData = seriesData;
      } else {
        console.log('Entered to assign series data slug to postdata');
        postData = seriesDataSlug;
      }
    }
    console.log('Watch Data for Series', postData);
    API.post(`track/api/v1/watchTime`, postData)
      .then((res) => {
        console.log('Successful post request', res);
      })
      .catch((err) => {
        console.error('Error', err);
      });
  }

  /**
   * Continue Watch: data to be post in every 10 seconds
   */
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentUser && !currentTrack?.live && !couponUser && !gpUser && !robiUser && !asthaUser) {
        postWatchData();
      }
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [location.search, seriesIndex, episodeIndex]);

  return (
    <>
      <VideoBox>
        <div data-vjs-player>
          <video
            ref={videoRef}
            onLoadedMetadata={handleLoadedMetadata}
            // onTimeUpdate={(e) => {
            //   if (e.target.currentTime >= vidDuration - 10) {
            //     setCallFinishVideoAPI(true);
            //   }
            // }}
            id="videojs-analytics-player"
            className="video-js react-player vjs-big-play-centered "
            controls
          />
          {/* {currentTrack?.live ? (
            <div className="eyeViewer">
              <img src={eyeSvg} alt="Live Icon" />
              <span>{liveCount}</span>
            </div>
          ) : (
            ''
          )} */}
          {!showIntroBtn ? (
            ''
          ) : (
            <button className="skip-btn" onClick={onSkipIntro}>
              <span>Skip Intro</span>
              <MdSkipNext size={20} />
            </button>
          )}
          {!showEndtitleBtn ? (
            ''
          ) : (
            <button className="skip-btn" onClick={onSkipEpisode}>
              <span>Next Episode</span>
              <MdSkipNext size={20} />
            </button>
          )}
          {!showSkipTrailerBtn ? (
            ''
          ) : (
            <button className="skip-btn" onClick={onHideSkipTrailer}>
              <span>Skip Trailer</span>
              <MdSkipNext size={20} />
            </button>
          )}
        </div>
      </VideoBox>
    </>
  );
}

const VideoBox = styled.div`
  background-color: var(--dark-navy-blue);
  .eyeViewer {
    position: absolute;
    top: 7%;
    left: 4%;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 12px;
  }
  .video-js .vjs-play-progress {
    background-color: var(--button-bg);
  }
  .video-js .vjs-play-progress {
    background-color: var(--button-bg);
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100% !important;
    width: 100%;
    /* z-index: 1; */

    @media (max-width: 767px) {
      height: 100%;
    }
  }
  .vjs-poster {
    background-size: cover;
    ::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .vjs-big-play-button {
    background-color: transparent !important;
    border: none !important;
    font-size: 70px !important;
  }
  .skip-btn {
    position: absolute;
    z-index: 999;
    padding: 0.8em 1.3em;
    outline: none;
    background: #000;
    right: 2.5em;
    bottom: 4.5em;
    border: 1px solid #aaa;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
  }
`;
